@use "./variables";
@use "./typography";
@use "./variables" as var;
@use "./colors-palettes.scss" as colors;

.cdk-overlay-container .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #2f3033;
  --mdc-plain-tooltip-supporting-text-color: #f2f0f4;
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: .75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: .025rem;
  --mdc-plain-tooltip-container-shape: 4px;
}

.heading-h5 {
  @include typography.th5-typography();
}

.heading-h6 {
  @include typography.th6-typography();
}

.subtitle-1 {
  @include typography.subtitle-1-typography();
}

.subtitle-2 {
  @include typography.subtitle-2-typography();
}

.caption-link {
  @include typography.caption-link-typography();
  text-decoration: underline;
}

.overline {
  @include typography.overline-typography();
}

.button-small {
  @include typography.button-small-typography();
}

.button-large {
  @include typography.button-small-typography();
}

.body-1-link {
  @include typography.body-1-link-typography();
  text-decoration: underline;
}

.body-2-link {
  @include typography.body-2-link-typography();
  text-decoration: underline;
}

.body-2-medium {
  @include typography.body-2-medium-typography();
}

.input-label {
  @include typography.input-label-typography();
}

.helper-text {
  @include typography.helper-text-typography();
}

.input-text-medium {
  @include typography.input-text-medium-typography();
}

.input-text-small {
  @include typography.input-text-small-typography();
}

.alert-title {
  @include typography.alert-title-typography();
}

.avatar-initials-medium {
  @include typography.avatar-initials-medium-typography();
}

.avatar-initials-small {
  @include typography.avatar-initials-small-typography();
}

.badge {
  @include typography.badge-typography();
}

.chip {
  @include typography.chip-label-typography();
}

.filterAction {
  @include typography.filterAction-typography();
}

.filterDefault {
  @include typography.filterDefault-typography();
}

.table-header {
  @include typography.table-header-typography();
}

.tooltip {
  @include typography.tooltip-typography();
}

.primary {
  color: var.$primary__main;
}

.background-color-primary {
  background-color: var.$primary__main;
}

.primary-inherit {
  color: var.$primary__inherit;
}

.background-color-primary-inherit {
  background-color: var.$primary__inherit;
}

// .primary-light {
//   // color is not defined
//   color: colors.$brand-green__300;
// }

// .background-color-primary-light {
//   // color is not defined
//   background-color: colors.$brand-green__300;
// }

.primary-dark {
  color: var.$primary__dark;
}

.background-color-primary-dark {
  background-color: var.$primary__dark;
}

.primary-contrast {
  color: var.$primary__contrast;
}

.background-color-primary-contrast {
  background-color: var.$primary__contrast;
}

.primary-state-hover {
  color: var.$primary__hover;
}

.background-color-primary-state-hover {
  background-color: var.$primary__hover;
}

// .primary-state-hover-contained-background {
//   // color is not defined
//   color: variables.$color-primary__state__hover-contained-background; // does note exists
// }

// .background-color-primary-state-hover-contained-background {
//   // color is not defined
//   background-color: variables.$color-primary__state__hover-contained-background; // does note exists
// }

// .primary-state-hover-on-selected {
//   // color is not defined
//   color: rgba(colors.$brand-green__500, 0.24);
// }

// .background-color-primary-state-hover-on-selected {
//   // color is not defined
//   background-color: rgba(colors.$brand-green__500, 0.24);
// }

// .primary-state-pressed {
//   // color is not defined
//   color: variables.$color-primary__state__pressed; // does note exists
// }

// .background-colorprimary-state-pressed {
//   // color is not defined
//   background-color: variables.$color-primary__state__pressed; // does note exists
// }

.primary-state-selected {
  color: var.$primary__selected;
}

.background-color-primary-state-selected {
  background-color: var.$primary__selected;
}

.primary-state-focus {
  color: var.$primary__focus;
}

.background-color-primary-state-focus {
  background-color: var.$primary__focus;
}

.primary-state-focus-visible {
  color: var.$primary__focusVisible;
}

.background-color-primary-state-focus-visible {
  background-color: var.$primary__focusVisible;
}

.primary-state-outlined-border {
  color: var.$primary__outlinedBorder;
}

.background-color-primary-state-outlined-border {
  background-color: var.$primary__outlinedBorder;
}

.primary-state-disabled-background {
  color: var.$action__disabledBackground;
}

.background-color-primary-state-disabled-background {
  background-color: var.$action__disabledBackground;
}

.primary-state-outlined-resting-border {
  $color: var.$primary__outlinedBorder;
}

.background-color-primary-state-outlined-resting-border {
  background-color: var.$primary__outlinedBorder;
}

.text-icon-primary {
  color: var.$text__primary;
}

.background-color-text-icon-primary {
  background-color: var.$text__primary;
}

.text-icon-secondary {
  color: var.$text__secondary;
}

.background-color-text-icon-secondary {
  background-color: var.$text__secondary;
}

.text-icon-disabled {
  color: var.$text__disabled;
}

.background-color-text-icon-disabled {
  background-color: var.$text__disabled;
}

.text-icon-contrast {
  color: var.$inherit__white-main;
}

.background-color-text-icon-contrast {
  background-color: var.$inherit__white-main;
}

.background-default {
  color: var.$background__default;
}

.background-color-background-default {
  background-color: var.$background__default;
}

.background-paper {
  color: var.$background__paper;
}

.background-color-background-paper {
  background-color: var.$background__paper;
}

.background-snackbar {
  color: var.$snackbar__defaultFill;
}

.background-color-background-snackbar {
  background-color: var.$snackbar__defaultFill;
}

// .background-tooltip {
//   // color is not defined
//   color: variables.$color-background__tooltip; // $black_58
// }

// .background-color-background-tooltip {
//   // color is not defined
//   background-color: variables.$color-background__tooltip; // $black_58
// }

// .background-overlay {
//   // color is not defined
//   color: variables.$color-background__overlay; // $black_15
// }

// .background-color-background-overlay {
//   // color is not defined
//   background-color: variables.$color-background__overlay; // $black_15
// }

.background-color-marketing-claas-green {
  background-color: var.$otherBrands__Claas;
}

.background-color-marketing-dark-green {
  background-color: var.$diagram__02;
}

.background-color-marketing-yellow {
  background-color: var.$diagram__05;
}

.background-color-marketing-mudd {
  background-color: var.$diagram__06;
}

.background-color-marketing-orange {
  background-color: var.$diagram__07;
}

.background-color-marketing-dark-orange {
  background-color: var.$diagram__08;
}

.background-color-marketing-light-blue {
  background-color: var.$diagram__09;
}

.background-color-marketing-dark-blue {
  background-color: var.$diagram__10;
}

.background-color-marketing-light-grey {
  background-color: var.$diagram__03;
}

.background-color-marketing-dark-grey {
  background-color: var.$diagram__04;
}

// .background-color-design-green-light {
//   // color is not defined
//   background-color: colors.$brand-green__300;
// }

.background-color-design-green-main {
  background-color: var.$primary__main;
}

.background-color-design-green-dark {
  background-color: var.$diagram__02;
}

// .background-color-design-green-special {
//   // color is not defined
//   background-color: colors.$olive__300;
// }

// .background-color-design-grey-light {
//   // color is not defined
//   background-color: colors.$warm-grey__200;
// }

.background-color-design-grey-main {
  background-color: var.$diagram__03;
}

.background-color-design-grey-dark {
  background-color: var.$diagram__04;
}

// .background-color-design-grey-special {
//   // color is not defined
//   background-color: colors.$warm-grey__600;
// }

// .background-color-design-brown-light {
//   // color is not defined
//   background-color: colors.$khaki__200;
// }

// .background-color-design-brown-main {
//   // color is not defined
//   background-color: colors.$khaki__400;
// }

// .background-color-design-brown-dark {
//   // color is not defined
//   background-color: colors.$khaki__600;
// }

// .background-color-design-yellow-light {
//   // color is not defined
//   background-color: colors.$gold__200;
// }

// .background-color-design-yellow-special {
//   // color is not defined
//   background-color: colors.$gold__600;
// }

// .background-color-design-yellow-main {
//   // color is not defined
//   background-color: colors.$gold__700;
// }

// .background-color-design-yellow-dark {
//   // color is not defined
//   background-color: colors.$gold__800;
// }

// .background-color-design-red-light {
//   // color is not defined
//   background-color: colors.$dark-salmon__500;
// }

.background-color-design-red-main {
  background-color: var.$diagram__07;
}

.background-color-design-red-special {
  background-color: colors.$dark-salmon__700;
}

.background-color-design-red-dark {
  background-color: var.$diagram__08;
}

.background-color-design-blue-dark {
  background-color: var.$diagram__10;
}

.background-color-design-blue-main {
  background-color: var.$diagram__09;
}

// .background-color-design-blue-special-1 {
//   // color is not defined
//   background-color: colors.$cool-blue__700;
// }

.background-color-design-blue-special-2 {
  // color is not defined
  background-color: colors.$cool-blue__300;
}

.background-color-design-blue-special-3 {
  // color is not defined
  background-color: colors.$cool-blue__100;
}

.background-color-design-blue-light {
  // color is not defined
  background-color: colors.$cool-blue__200;
}

.secondary {
  color: var.$secondary__main;
}

.background-color-secondary-main {
  background-color: var.$secondary__main;
}

.secondary-light {
  color: var.$secondary__light;
}

.background-color-secondary-light {
  background-color: var.$secondary__light;
}

.secondary-dark {
  // color is not defined
  color: colors.$warm-grey__600;
}

.background-color-secondary-dark {
  // color is not defined
  background-color: colors.$warm-grey__600;
}

.secondary-contrast {
  color: var.$inherit__white-main;
}

.background-color-secondary-contrast {
  background-color: var.$inherit__white-main;
}

.background-color-secondary-contrast-border {
  background-color: var.$action__focus;
}

.secondary-state-hover {
  color: var.$secondary__hover;
}

.background-color-secondary-state-hover {
  background-color: var.$secondary__hover;
}

.secondary-state-focus {
  color: var.$secondary__focus;
}

.background-color-secondary-state-focus {
  background-color: var.$secondary__focus;
}

.secondary-state-focus-visible {
  color: var.$secondary__focusVisible;
}

.background-color-secondary-state-focus-visible {
  background-color: var.$secondary__focusVisible;
}

.secondary-state-outlined-border {
  color: var.$secondary__outlinedBorder;
}

.background-color-secondary-state-outlined-border {
  background-color: var.$secondary__outlinedBorder;
}

.secondary-state-hover-contained-background {
  // color is not defined
  color: #4b4b4b;
}

.background-color-secondary-state-hover-contained-background {
  // color is not defined
  background-color: #4b4b4b;
}

.secondary-state-pressed {
  // color is not defined
  color: #9b9b9b;
}

.background-color-secondary-state-pressed {
  // color is not defined
  background-color: #9b9b9b;
}

.secondary-state-selected {
  color: var.$action__selected;
}

.background-color-secondary-state-selected {
  background-color: var.$action__selected;
}

.secondary-state-disabled-background {
  color: var.$action__disabledBackground;
}

.background-color-secondary-state-disabled-background {
  background-color: var.$action__disabledBackground;
}

.secondary-state-outlined-resting-border {
  // color is not defined
  color: rgba(#6b6b6b, 0.5);
}

.background-color-secondary-state-outlined-resting-border {
  // color is not defined
  background-color: rgba(#6b6b6b, 0.5);
}

.background-color-textfield-outline-border-dark {
  // color is not defined
  background-color: #616161;
}

.background-color-textfield-outline-border- {
  background-color: var.$action__disabledBackground;
}

.background-color-textfield-standard-input-line {
  // color is not defined
  background-color: rgba(#000000, 0.42);
}

.background-color-map-legend-dark {
  // color is not defined
  background-color: rgba(#000000, 0.4);
}

.background-color-map-legend-light {
  // color is not defined
  background-color: rgba(var.$background__default, 0.8);
}

.background-color-map-track-blue {
  background-color: var.$driveTrack__track03;
}

.background-color-map-track-orange {
  background-color: var.$driveTrack__track08;
}

.background-color-map-pin-event {
  // color is not defined
  background-color: colors.$petrol__500;
}

.background-color-claas-logo-red {
  // color is not defined
  background-color: #fe0001;
}

.background-color-claas-promo-orange {
  // color is not defined
  background-color: #d14900;
}

.error {
  color: var.$error__main;
}

.background-color-error {
  background-color: var.$error__main;
}

.error-light {
  // color is not defined
  color: #f88078;
}

.background-color-error-light {
  // color is not defined
  background-color: #f88078;
}

.error-dark {
  color: var.$error__dark;
}

.background-color-error-dark {
  background-color: var.$error__dark;
}

.error-contrast {
  color: var.$error__contrast;
}

.background-color-error-contrast {
  background-color: var.$error__contrast;
}

.error-state-hover {
  color: var.$error__hover;
}

.background-color-error-state-hover {
  background-color: var.$error__hover;
}

.error-state-selected {
  color: var.$error__selected;
}

.background-color-error-state-selected {
  background-color: var.$error__selected;
}
.error-state-focus-visible {
  color: var.$error__focusVisible;
}

.background-color-error-state-focus-visible {
  background-color: var.$error__focusVisible;
}
.error-state-outlined-border {
  color: var.$error__outlinedBorder;
}

.background-color-error-state-outlined-border {
  background-color: var.$error__outlinedBorder;
}

.error-alert-error-content {
  color: var.$error__errorContent;
}

.background-color-error-alert-error-content {
  background-color: var.$error__errorContent;
}
.error-alert-error-fill {
  color: var.$error__errorFill;
}

.background-color-error-alert-error-fill {
  background-color: var.$error__errorFill;
}
.error-alert-error-bage-fill {
  color: var.$error__errorBageFill;
}

.background-color-error-alert-error-bage-fill {
  background-color: var.$error__errorBageFill;
}

.error-contained-hover-color {
  // color is not defined
  color: colors.$dark-red__500;
}

.background-color-error-contained-hover-color {
  // color is not defined
  background-color: colors.$dark-red__500;
}

.warning-outlined-error-color {
  // color is not defined
  color: rgba(#f44336, 0.08);
}

.background-color-error-outlined-hover-color {
  // color is not defined
  background-color: rgba(#f44336, 0.08);
}

.error-outlined-resting-border-color {
  // color is not defined
  color: rgba(#f44336, 0.5);
}

.background-color-error-outlined-resting-border-color {
  // color is not defined
  background-color: rgba(#f44336, 0.5);
}

.error-component-text-color {
  color: var.$error__errorContent;
}

.background-color-error-component-text-color {
  background-color: var.$error__errorContent;
}

.error-component-background-color {
  color: var.$error__errorFill;
}

.background-color-error-component-background-color {
  background-color: var.$error__errorFill;
}

.warn {
  color: var.$warning__main;
}

.background-color-warn {
  background-color: var.$warning__main;
}

.warning-light {
  // color is not defined
  color: #ffb547;
}

.background-color-warning-light {
  // color is not defined
  background-color: #ffb547;
}

.warning-dark {
  color: var.$warning__dark;
}

.background-color-warning-dark {
  background-color: var.$warning__dark;
}

.warning-contrast {
  color: var.$warning__contrast;
}

.background-color-warning-contrast {
  background-color: var.$warning__contrast;
}

.warning-contained-hover-color {
  // color is not defined
  color: #b36b00;
}

.background-color-warning-contained-hover-color {
  // color is not defined
  background-color: #b36b00;
}

.warning-outlined-hover-color {
  // color is not defined
  color: rgba(colors.$orange__300, 0.08);
}

.background-color-warning-outlined-hover-color {
  // color is not defined
  background-color: rgba(colors.$orange__300, 0.08);
}

.warning-outlined-resting-border-color {
  // color is not defined
  color: rgba(colors.$orange__300, 0.5);
}

.background-color-warning-outlined-resting-border-color {
  // color is not defined
  background-color: rgba(colors.$orange__300, 0.5);
}

.warning-component-text-color {
  color: var.$warning__warningContent;
}

.background-color-warning-component-text-color {
  background-color: var.$warning__warningContent;
}

.warning-component-background-color {
  color: var.$warning__warningFill;
}

.background-color-warning-component-background-color {
  background-color: var.$warning__warningFill;
}

.warning-state-hover {
  color: var.$warning__hover;
}

.background-color-warning-state-hover {
  background-color: var.$warning__hover;
}

.warning-state-selected {
  color: var.$warning__selected;
}

.background-color-warning-state-selected {
  background-color: var.$warning__selected;
}
.warning-state-focus-visible {
  color: var.$warning__focusVisible;
}

.background-color-warning-state-focus-visible {
  background-color: var.$warning__focusVisible;
}
.warning-state-outlined-border {
  color: var.$warning__outlinedBorder;
}

.background-color-warning-state-outlined-border {
  background-color: var.$warning__outlinedBorder;
}
.warning-alert-warning-content {
  color: var.$warning__warningContent;
}

.background-color-warning-alert-warning-content {
  background-color: var.$warning__warningContent;
}
.warning-alert-warning-fill {
  color: var.$warning__warningFill;
}

.background-color-warning-alert-warning-fill {
  background-color: var.$warning__warningFill;
}
.warning-alert-warning-badge-fill {
  color: var.$warning__warningBageFill;
}

.background-color-warning-alert-warning-badge-fill {
  background-color: var.$warning__warningBageFill;
}

.info {
  color: var.$info__main;
}

.background-color-info {
  background-color: var.$info__main;
}

.info-light {
  // color is not defined
  color: #64b6f7;
}

.background-color-info-light {
  // color is not defined
  background-color: #64b6f7;
}

.info-dark {
  color: var.$info__main;
}

.background-color-info-dark {
  background-color: var.$info__main;
}

.info-contrast {
  color: var.$info__contrast;
}

.background-color-info-contrast {
  background-color: var.$info__contrast;
}

.info-contained-hover-color {
  // color is not defined
  color: #1769ab;
}

.background-color-info-contained-hover-color {
  // color is not defined
  background-color: #1769ab;
}

.info-outlined-hover-color {
  // color is not defined
  color: rgba(#2094f3, 0.5);
}

.background-color-info-outlined-hover-color {
  // color is not defined
  background-color: rgba(#2094f3, 0.5);
}

.info-outlined-resting-border-color {
  // color is not defined
  color: rgba(#2196f3, 0.5);
}

.background-color-info-outlined-resting-border-color {
  // color is not defined
  background-color: rgba(#2196f3, 0.5);
}

.info-component-text-color {
  color: var.$info__infoContent;
}

.background-color-info-component-text-color {
  background-color: var.$info__infoContent;
}

.info-component-background-color {
  color: var.$info__infoFill;
}

.background-color-info-component-background-color {
  background-color: var.$info__infoFill;
}

.info-state-hover {
  color: var.$info__hover;
}

.background-color-info-state-hover {
  background-color: var.$info__hover;
}

.info-state-selected {
  color: var.$info__selected;
}

.background-color-info-state-selected {
  background-color: var.$info__selected;
}
.info-state-focus-visible {
  color: var.$info__focusVisible;
}

.background-color-info-state-focus-visible {
  background-color: var.$info__focusVisible;
}
.info-state-outlined-border {
  color: var.$info__outlinedBorder;
}

.background-color-info-state-outlined-border {
  background-color: var.$info__outlinedBorder;
}
.info-alert-info-content {
  color: var.$info__infoContent;
}

.background-color-info-alert-info-content {
  background-color: var.$info__infoContent;
}
.info-alert-info-fill {
  color: var.$info__infoFill;
}

.background-color-info-alert-info-fill {
  background-color: var.$info__infoFill;
}
.info-alert-info-badge-fill {
  color: var.$info__infoBageFill;
}

.background-color-info-alert-info-badge-fill {
  background-color: var.$info__infoBageFill;
}

.success {
  color: var.$success__main;
}

.background-color-success {
  background-color: var.$success__main;
}

.success-light {
  // color is not defined
  color: #7bc67e;
}

.background-color-success-light {
  // color is not defined
  background-color: #7bc67e;
}

.success-dark {
  color: var.$info__main;
}

.background-color-success-dark {
  background-color: var.$info__main;
}

.success-contrast {
  color: var.$success__contrast;
}

.background-color-success-contrast {
  background-color: var.$success__contrast;
}

.success-contained-hover-color {
  // color is not defined
  color: #357b38;
}

.background-color-success-contained-hover-color {
  // color is not defined
  background-color: #357b38;
}

.success-outlined-hover-color {
  // color is not defined
  color: rgba(colors.$green__300, 0.08);
}

.background-color-success-outlined-hover-color {
  // color is not defined
  background-color: rgba(colors.$green__300, 0.08);
}

.success-outlined-resting-border-color {
  // color is not defined
  color: rgba(colors.$green__300, 0.5);
}

.background-color-success-outlined-resting-border-color {
  // color is not defined
  background-color: rgba(colors.$green__300, 0.5);
}

.success-component-text-color {
  color: var.$success__successContent;
}

.background-color-success-component-text-color {
  background-color: var.$success__successContent;
}

.success-component-background-color {
  color: var.$success__successFill;
}

.background-color-success-component-background-color {
  background-color: var.$success__successFill;
}

.success-state-hover {
  color: var.$success__hover;
}

.background-color-success-state-hover {
  background-color: var.$success__hover;
}

.success-state-selected {
  color: var.$success__selected;
}

.background-color-success-state-selected {
  background-color: var.$success__selected;
}
.success-state-focus-visible {
  color: var.$success__focusVisible;
}

.background-color-success-state-focus-visible {
  background-color: var.$success__focusVisible;
}
.success-state-outlined-border {
  color: var.$success__outlinedBorder;
}

.background-color-success-state-outlined-border {
  background-color: var.$success__outlinedBorder;
}
.success-alert-success-content {
  color: var.$success__successContent;
}

.background-color-success-alert-success-content {
  background-color: var.$success__successContent;
}
.success-alert-sucess-fill {
  color: var.$success__successFill;
}

.background-color-success-alert-success-fill {
  background-color: var.$success__successFill;
}
.success-alert-success-badge-fill {
  color: var.$success__successBageFill;
}

.background-color-success-alert-success-badge-fill {
  background-color: var.$success__successBageFill;
}

.action-active {
  color: var.$action__active;
}

.background-color-action-active {
  background-color: var.$action__active;
}

.action-hover {
  color: var.$action__hover;
}

.background-color-action-hover {
  background-color: var.$action__hover;
}

.action-selected {
  color: var.$action__selected;
}

.background-color-action-selected {
  background-color: var.$action__selected;
}

.action-disabled {
  color: var.$action__disabled;
}

.background-color-action-disabled {
  background-color: var.$action__disabled;
}

.action-disabled-background {
  color: var.$action__disabledBackground;
}

.background-color-action-disabled-background {
  background-color: var.$action__disabledBackground;
}

.action-focus {
  color: var.$action__focus;
}

.background-color-action-focus {
  background-color: var.$action__focus;
}

.text-primary {
  color: var.$text__primary;
}
.background-color-text-primary {
  background-color: var.$text__primary;
}

.text-secondary {
  color: var.$text__secondary;
}
.background-color-text-secondary {
  background-color: var.$text__secondary;
}
.text-disabled {
  color: var.$text__disabled;
}
.background-color-text-disabled {
  background-color: var.$text__disabled;
}

.padding-right-8 {
  padding-right: 0.5rem;
}

.padding-right-12 {
  padding-right: 0.75rem;
}

.padding-left-8 {
  padding-left: 0.5rem;
}

.padding-left-12 {
  padding-left: 0.75rem;
}

.padding-left-16 {
  padding-left: 1rem;
}

// scrollbar
body::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

body::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background-color: var.$switch__knobFill;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var.$neutral__focusVisible;
  border-radius: 8px;
  border: 4px solid var.$switch__knobFill;

  &:hover {
    border: 2px solid var.$switch__knobFill;
  }
}

//firefox
@supports (-moz-appearance: none) {
  html {
    overflow: scroll;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: var.$neutral__focusVisible var.$switch__knobFill; /* scroll thumb and track */
  }
}

.margin-top-16 {
  margin-top: 1rem;
}

.margin-right-8 {
  margin-right: 0.5rem;
}

.margin-right-12 {
  margin-right: 0.75rem;
}

.inline-flex {
  display: inline-flex;
}

.dropdown-overlay-panel .mdc-menu-surface.mat-mdc-select-panel {
  max-height: 376px;
}
.cdk-overlay-pane.dropdown-overlay-panel {
  &:has(.mat-mdc-select-panel):has(.hugMenu) {
    width: auto !important;
  }
  .selectPanelClass {
    border-radius: var(--radius-radius-default, 4px) !important;
  }
}
