@use "@angular/material" as mat;
@import url('https://cdn.claas.com/common/fonts/Roboto/complete/roboto.css');

// headline-5       Section heading corresponding to the <h1> tag.
// headline-6       Section heading corresponding to the <h2> tag.
// subtitle-1 	        Section heading corresponding to the <h3> tag.
// subtitle-2   Section heading corresponding to the <h4> tag.
// button         Equal to button medium
$claas-default-font-family: Roboto;
$fontConfig: mat.define-typography-config(
        $font-family: $claas-default-font-family,
        $headline-5: mat.define-typography-level(2rem, 2.5rem, 400, $letter-spacing: 0.009375rem),
        $headline-6: mat.define-typography-level(1.75rem, 2rem, 400, $letter-spacing: 0.009375rem),
        $subtitle-1: mat.define-typography-level(1.5rem, 2rem, 400, $letter-spacing: 0.009375rem),
        $subtitle-2: mat.define-typography-level(1.25rem, 1.75rem, 400, $letter-spacing: 0.009375rem),
        $body-2: mat.define-typography-level(0.875rem, 1.25rem, 400, $claas-default-font-family, $letter-spacing: 0.009375rem),
        $body-1: mat.define-typography-level(1rem, 1.5rem, 400, $letter-spacing: 0.009375rem),
        $button: mat.define-typography-level(1rem, 1.5rem, 500, $letter-spacing: 0.009375rem),
        $caption: mat.define-typography-level(0.75rem, 1rem, 400, $letter-spacing: 0.03125rem), // Line-height must be unit-less fraction of the font-size.
);

// CUSTOM TYPOGRAPHY NOT CONTAINED IN MATERIAL
@mixin th5-typography() {
  @include define-typography(1.25rem, 1.75rem, 500, $letter-spacing: 0.009375rem);
}

@mixin th6-typography() {
  @include define-typography(1.125rem, 1.625rem, 400, $claas-default-font-family, $letter-spacing: 0.009375rem);
}

@mixin subtitle-1-typography() {
  @include define-typography(1rem, 1.5rem, 500, $letter-spacing: 0.009375rem);
}

@mixin subtitle-2-typography {
  @include define-typography(0.875rem, 1.25rem, 500, $letter-spacing: 0.009375rem);
}

@mixin body-1-link-typography {
  @include define-typography(1rem, 1.5rem, 400, $claas-default-font-family, $letter-spacing: 0.0015rem);
}

@mixin body-2-link-typography {
  @include define-typography(0.875rem, 1.25rem, 400, $claas-default-font-family, $letter-spacing: 0.0013125rem);
}

@mixin body-2-medium-typography {
  @include define-typography(0.875rem, 1.25rem, 500, $letter-spacing: 0.0013125rem);
}

@mixin caption-link-typography{
  @include define-typography(0.75rem, 1rem,400, $claas-default-font-family,$letter-spacing: 0.03125rem)
}

@mixin overline-typography() {
  @include define-typography(0.75rem, 1rem, 400, $claas-default-font-family, $letter-spacing: 0.03125rem);
}

@mixin alert-title-typography() {
  @include define-typography(0.875rem, 1.25rem, 500, $letter-spacing: 0.009375rem);
}

@mixin avatar-initials-medium-typography() {
  @include define-typography(1.25rem, 1.25rem, 400, $claas-default-font-family, $letter-spacing: 0.00875rem);
}

@mixin avatar-initials-small-typography() {
  @include define-typography(0.875rem, 0.875rem, 400, $claas-default-font-family, $letter-spacing: 0.0013125rem);
}

@mixin badge-typography() {
  @include define-typography(0.75rem, 0.75rem, 400, $claas-default-font-family, $letter-spacing: 0rem);
}

@mixin chip-label-typography() {
  @include define-typography(0.8125rem, 1.125rem, 400, $claas-default-font-family, $letter-spacing: 0.01rem);
}

@mixin filterAction-typography() {
  @include define-typography(0.875rem, 1.25rem, 500, $letter-spacing: 0.0013125rem);
}

@mixin filterDefault-typography() {
  @include define-typography(0.875rem, 1.25rem, 400, $claas-default-font-family, $letter-spacing: 0.0013125rem);
}

@mixin input-label-typography() {
  @include define-typography(0.75rem, 0.75rem, 400, $claas-default-font-family, $letter-spacing: 0.009375rem);
}

@mixin helper-text-typography() {
  @include define-typography(0.75rem, 1rem, 400, $claas-default-font-family, $letter-spacing: 0.025rem);
}

@mixin input-text-medium-typography() {
  @include define-typography(1rem, 1.5rem, 400, $claas-default-font-family, $letter-spacing: 0.009375rem);
}

@mixin input-text-small-typography() {
  @include define-typography(0.875rem, 1.25rem, 400, $claas-default-font-family, $letter-spacing: 0.0013125rem);
}

@mixin table-header-typography() {
  @include define-typography(0.875rem, 1.25rem, 500, $letter-spacing: 0.009375rem);
}

@mixin tooltip-typography() {
  @include define-typography(0.625rem, 0.875rem, 400, $letter-spacing: 0rem);
}

@mixin button-large-typography() {
  @include define-typography(1.0625rem, 1.625rem, 500, $letter-spacing: 0.02875rem);
}

@mixin button-small-typography() {
  @include define-typography(0.9375rem, 1.375rem, 500, $letter-spacing: 0.009375rem);
}

@mixin define-typography($font-size, $line-height, $font-weight, $font-family : $claas-default-font-family, $letter-spacing) {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
}

