@use "app/shared/styles/variables";
/* You can add global styles to this file, and also import other style files */
@import 'node_modules/@claas/claas-theme-component/src/styles.scss';
@import 'node_modules/@claas/claas-theme-component/src/lib/styles/variables';
@import 'node_modules/@claas/claas-theme-component/src/lib/styles/typography';
//@import '/src/app/shared/styles/variables.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    background-color: $background__default;
}

claas-icon-button {
    color: $text__secondary;
}

.layout-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $background__paper;
    border-bottom: 1px;
    border-style: solid;
    border-color: $divider__divider;
}

.header {
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    height: 56px;
}

.menu-button {
    padding-right: 24px;
}

.progession {
    width: 98.033px;
    margin-right: 8px;
    margin-left: 20px;
    background-image: url('/assets/images/Progession.svg');
}

.logo-icon {
    width: 91px;
    background-image: url('/assets/images/Frame 4146.svg');
}

.divider-vertical {
    height: 32.4px !important;
    padding-left: 20px;
}

.icon-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    letter-spacing: 0.151px;
    color: $text__primary;
}

.header-right {
    margin-left: auto;
    padding-right: 16px;
}

.icon-button {
    padding: 12px;
}

claas-app-launcher {
    padding-right: 12px;
    padding-top: 18px;
    color: $text__secondary;
}

#drawerContent {
    top: 57px;
}

.mat-drawer {
    border-style: solid;
    border-color: $divider__divider;
    border-right-width: 1px;
    background-color: $background__paper;
    padding-bottom: 100px;
}

#drawerContent {
  background-color: unset;
}

.inner-hyperlink a:hover {
    color: $primary__main;
  }

.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background {
  background-color: variables.$primary-color-green;
}

.mat-primary .mat-mdc-checkbox-checked:not(.use-ui-component-lib *) {
  background: #b3c618;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled):not(.use-ui-component-lib *) {
  color: #b3c618;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar:not(.use-ui-component-lib *) {
  background-color: rgba(179,198,24,.54);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb:not(.use-ui-component-lib *) {
  background-color: #b3c618;
}

.mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple:not(.use-ui-component-lib *) {
  background-color: #b3c618;
}

.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent .mat-ripple-element:not(.use-ui-component-lib *), .mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent .mat-ripple-element:not(.use-ui-component-lib *)  {
  background-color: #b3c618;
}

.mat-mdc-dialog-container {
  .organisation-registration-dialog-stepper {
    .mdc-floating-label {
      background-color: white;
    }

    .mat-mdc-select-arrow {
      color: variables.$border-color-gray-darker;
    }

    .mat-mdc-text-field-wrapper {
      border: 1px solid variables.$border-color-gray-darker;
      border-radius: 4px;
    }
  }
}

