@use "./variables" as var;

.badge-container {
  position: relative;
}

.badgeComponent {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var.$primary__main;
  color: var.$text__primary;
  font-size: 12px;
  border-radius: 50%;
}
.badgeComponent.primary {
  background-color: var.$primary__main;
}

.badgeComponent.secondary {
  background-color: var.$secondary__light;
}

.badgeComponent.error {
  background-color: var.$error__main;
}

.badgeComponent.light {
  background-color: var.$background__default;
}

.badgeComponent.medium {
  width: 20px;
  height: 20px;
  top: -10px;
  right: -10px;
}

.badgeComponent.noText {
  width: 0.5rem;
  height: 0.5rem;
  top: -5px;
  right: -5px;
}

.badgeComponent.icon {
  width: auto;
  height: 20px;
  top: -10px;
  right: -30px;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 64px;
  span {
    padding-right: 4px;
    padding-left: 4px;
  }
}
