/*   spacing   */
$xs-spacing : 5px;
$s-spacing : 10px;
$m-spacing : 15px;
$l-spacing : 20px;
$xl-spacing : 36px;

$design-xs-spacing: 8px;
$design-m-spacing: 16px;
$design-l-spacing: 24px;
$design-xl-spacing: 34px;

/*  font size   */
$footnote-font-size: 10px;
$small-text-font-size: 12px;
$label-font-size : 14px;
$text-font-size : 16px;

$text-line-height : 24px;
$h3-line-height: 32px;

$h1-font-size : 32px;
$h2-font-size : 24px;
$h3-font-size : 20px;

$grid-header-height: 40px;
$grid-cell-height: 48px;

/*   chips   */
$chip-height: 28px;


/*   buttons    */
$button-warn: #C62828;


/*   dimensions    */
$dialog-window-width : 520px;
$dialog-window-width-handset : 370px;
$overview-first-block : 255px;

/*   colors   */
$border-color-gray: #E0E0E0;
$border-color-gray-darker: #9E9E9E;
$border-field-color-gray: #8E8E8E;
$text-color-gray: rgba(#000000, 0.65);
$text-color-black: rgba(#000000, 0.87);
$accepted-background-color-green: #C8E6C9;
$accepted-text-color-green: #1e4620;
$pending-background-color-blue: #BBDEFB;
$pending-text-color-blue: #0d3c61;
$primary-color-green: #b3c719;
$valid-text-color-green: #B3C618;
$error-text-color-red: #C62828;

// Font defaults

$claas-light-default-font-weight: 300 !important;
$claas-bold-default-font-weight: 500 !important;

/*    headers    */
.header-2-light {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.15px;
}

.header-3-light {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.header-3-bold {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.label-1-style {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: rgba(0,0,0,0.67);
}

@mixin text-1-style {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0,0,0,0.87);
}

@mixin header-1-style {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.15px;
  color: rgba(0,0,0,0.87);
}
