// Brand Color Palettes

$brand-green__300: #cbda7c;
$brand-green__400: #cadf1c;
$brand-green__500: #b3c618;
$brand-green__600: #8d9d00;
$brand-green__700: #6f7e02;

$silver__400: #c7c7c7;
$silver__700: #545454;
$silver__800: #424242;

// Basic Color Palettes

$white: #ffffff;
$grey__50: #fafafa;
$grey__100: #f0f0f0;
$grey__200: #ededed;
$grey__300: #e0e0e0;
$grey__400: #b2b2b2;
$grey__500: #8f8f8f;
$grey__600: #808080;
$grey__700: #5c5c5c;
$grey__800: #323232;
$grey__900: #212121;
$grey__950: #151515;
$black: #000000;

//Notification Color Palettes

$green__100: #eff7ee;
$green__200: #c8e6c9;
$green__250: #7bc67e;
$green__300: #4caf50;
$green__500: #2e7d32;
$green__700: #1b5e20;
$green__900: #1e4620;

$red__100: #fde1df;
$red__200: #fecdd2;
$red__300: #f34643;
$red__500: #e31b0c;
$red__700: #c62828;
$red__900: #621b16;

$orange__100: #fdf4e7;
$orange__200: #ffe0b2;
$orange__250: #ffb547;
$orange__300: #ff9800;
$orange__500: #eb6405;
$orange__700: #e65100;
$orange__900: #663d00;

$blue__100: #eaf4fc;
$blue__200: #bbdefb;
$blue__300: #03a9f4;
$blue__500: #0b79d0;
$blue__700: #01579b;
$blue__900: #0d3c61;

// Additional Color Palettes

$petrol__200: #cbf7fa;
$petrol__300: #9df5fb;
$petrol__500: #5fa0a7;
$petrol__600: #86afb3;
$petrol__900: #254e5a;

$blueberry__300: #70cdff;
$blueberry__500: #5c98ca;
$blueberry__800: #44498b;

$muddy-green__100: #e0edbe;
$muddy-green__200: #c0dbb0;
$muddy-green__300: #b0bfa2;
$muddy-green__500: #c9f551;
$muddy-green__900: #627c38;

$sea-green__200: #80b693;
$sea-green__400: #419176;
$sea-green__600: #217f68;
$sea-green__800: #016c59;

$mint__300: #3ce297;
$mint__500: #32b8a6;
$mint__700: #418f84;

$orange-yellow__200: #fee391;
$orange-yellow__300: #fec44f;
$orange-yellow__400: #ffb400;
$orange-yellow__500: #fe9929;
$orange-yellow__700: #d1943f;

$rust-red__300: #ffa047;
$rust-red__500: #cc4c02;
$rust-red__700: #8c2d04;
$rust-red__800: #73241f;

$yellow__100: #ffffcc;
$yellow__200: #f9f6b2;
$yellow__300: #f7ec88;
$yellow__400: #f9e176;

$brown__200: #d9d78c;
$brown__400: #ad9d63;
$brown__500: #977210;
$brown__900: #553e02;

$tan__100: #e5d38b;
$tan__200: #cbbd98;
$tan__300: #c0ac94;
$tan__600: #8c7e6d;

$purple-magenta__100: #dfc1eb;
$purple-magenta__400: #ff1fb4;
$purple-magenta__500: #ad00f2;
$purple-magenta__600: #9c5596;
$purple-magenta__800: #6e6572;

$rose__100: #f5dacc;
$rose__200: #e8c4b2;
$rose__600: #bf8e8e;

// CLAAS Corperate Identity Colors

$claas-highlight-color: #ff5a00;
$claas-signal-red: #aa0b25;
$claas-signal-orange: #ce5503;
$claas-signal-yellow: #e8a900;
$claas-signal-green: #00853e;

$cool-blue__100: #dee9f0;
$cool-blue__200: #bcd3e0;
$cool-blue__300: #a1bbcd;
$cool-blue__500: #81a4bc;
$cool-blue__700: #7c8f9c;
$cool-blue__800: #5a7384;

$warm-grey__100: #eeeeee;
$warm-grey__200: #d7d7d7;
$warm-grey__300: #999999;
$warm-grey__400: #696969;
$warm-grey__600: #444444;

$gold__100: #ecd988;
$gold__200: #fdd556;
$gold__300: #f6db00;
$gold__500: #f3a800;
$gold__600: #e7c02d;
$gold__700: #c6a81b;
$gold__800: #a08715;

$dark-salmon__500: #e2a17a;
$dark-salmon__700: #b08068;
$dark-salmon__800: #965535;

$dark-red__300: #e27036;
$dark-red__400: #cb3c3a;
$dark-red__500: #ab2f26;
$dark-red__700: #92352c;
$dark-red__900: #621b16;

$olive__300: #99a34c;
$olive__500: #778410;

$khaki__200: #c5bda0;
$khaki__400: #928e6c;
$khaki__600: #726c4a;

$forestgreen__200: #849c6d;
$forestgreen__500: #50af00;
$forestgreen__800: #4c683e;

$petrol__200: #2f00ed;
$petrol__300: #522bde;
$petrol__500: #7556ce;
$petrol__550: #9680bf;
$petrol__600: #b9abaf;
$petrol__800: #dbd59f;
$petrol__900: #fdff90;
