@use "@angular/material" as mat;
@use "./variables" as var;
@use "./colors-palettes.scss" as colors;
@import "./typography";

// Foreground Elements
$mat-light-theme-foreground: (
  base: var.$inherit__textPrimary,
  divider: var.$divider__divider,
  dividers: var.$divider__divider,
  disabled: colors.$brand-green__300,
  disabled-button: colors.$brand-green__300,
  disabled-text: var.$text__disabled,
  secondary-text: var.$text__secondary,
  hint-text: var.$text__disabled,
  accent-text: var.$text__secondary,
  icon: rgba(colors.$black, 0.58),
  icons: rgba(colors.$black, 0.58),
  text: var.$text__primary,
  slider-min: var.$text__primary,
  slider-off-active: var.$action__disabled,
);

// Background config
// Light bg
$mat-light-theme-background: (
  background: var.$background__paper,
  card: var.$background__paper,
  dialog: var.$background__paper,
);

// Theme Config
body {
  --primary-color: var.$primary__main;
  --primary-lighter-color: var.$primary__main;
  --primary-darker-color: var.$primary__dark;
  --text-primary-color: #{var.$primary__contrast};
  --text-primary-lighter-color: #{var.$primary__contrast};
  --text-primary-darker-color: #{var.$primary__contrast};
}

$mat-primary: (
  main: var.$primary__main,
  lighter: var.$primary__main,
  darker: var.$primary__dark,
  200: var.$primary__main,
  // For slide toggle,
  contrast:
    (
      main: var.$primary__contrast,
      lighter: var.$primary__contrast,
      darker: var.$primary__contrast,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: var.$secondary__main;
  --accent-lighter-color: var.$secondary__main;
  --accent-darker-color: var.$secondary__main;
  --text-accent-color: #{var.$background__paper};
  --text-accent-lighter-color: #{var.$background__paper};
  --text-accent-darker-color: #{var.$background__paper};
}

$mat-accent: (
  main: var.$secondary__main,
  lighter: var.$secondary__main,
  darker: var.$secondary__main,
  200: var.$secondary__main,
  // For slide toggle,
  contrast:
    (
      main: var.$background__paper,
      lighter: var.$background__paper,
      darker: var.$background__paper,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: var.$error__main;
  --warn-lighter-color: var.$error__main;
  --warn-darker-color: var.$error__dark;
  --text-warn-color: #{var.$error__contrast};
  --text-warn-lighter-color: #{var.$error__contrast};
  --text-warn-darker-color: #{var.$error__contrast};
}

$mat-warn: (
  main: var.$error__main,
  lighter: var.$error__main,
  darker: var.$error__dark,
  200: var.$error__main,
  // For slide toggle,
  contrast:
    (
      main: var.$error__contrast,
      lighter: var.$error__contrast,
      darker: var.$error__contrast,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: (
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
  ),
  typography: $fontConfig,
);

// Compute font config
@include mat.core();

// Theme Init
@include mat.all-component-themes($theme);
@include mat.all-component-typographies($fontConfig);
